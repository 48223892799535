<template>
  <v-data-table
    :headers="headers"
    :items="filteredCourses"
    :items-per-page="10"
    class="elevation-1"
    :loading="isLoading"
    loading-text="Loading... Please wait"
  >
    <!-- TODO: add filter text field -->
    <!-- <template v-slot:top>
      <v-text-field label="Searc" class="mx-4"></v-text-field>
    </template> -->
    <template v-slot:item.userId="{ item }">
      <base-profile-avatar :size="50" :user="hashUsers[item.userId]" />
    </template>
    <template v-slot:item.amount="{ item }">
      <v-chip color="primary" outlined>
        {{ item.amount }}
      </v-chip>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon color="primary">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(option, i) in rowItemActions"
            :key="i"
            @click="option.action(item)"
          >
            <v-list-item-title>{{ option.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'CoursesTable',

  props: {
    status: {
      type: String,
      default: 'pending',
    },
  },

  components: {
    BaseProfileAvatar: () => import('@/components/BaseProfileAvatar'),
  },

  data: () => ({
    headers: [
      {
        text: 'User',
        align: 'start',
        sortable: true,
        value: 'userId',
      },
      {
        text: 'Start Date',
        align: 'start',
        sortable: true,
        value: 'startDate',
      },
      {
        text: 'Course Name',
        align: 'start',
        sortable: false,
        value: 'courseName',
      },
      { text: 'Amount', value: 'amount' },
      { text: 'Description', value: 'description', sortable: false },
      { text: 'Status', value: 'status', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
  }),

  created() {
    this.bindUsersRef();
    this.bindCoursesRef();
  },

  computed: {
    ...mapState('courses', ['isLoading', 'courses']),
    ...mapState('users', ['users']),
    ...mapGetters('users', ['hashUsers']),
    ...mapGetters('auth', ['isAdmin']),
    filteredCourses() {
      return this.courses.filter((course) => course.status === this.status);
    },
    rowItemActions() {
      return this.isAdmin
        ? [
            {
              label: 'Approve',
              action: (course) =>
                this.$emit('changeStatus', 'courses', course.id, 'approved'),
            },
            {
              label: 'Reject',
              action: (course) =>
                this.$emit('changeStatus', 'courses', course.id, 'rejected'),
            },
            {
              label: 'Edit',
              action: (course) => this.$emit('onEdit', 'courses', course.id),
            },
            {
              label: 'Delete',
              action: (course) => this.$emit('onDelete', 'courses', course.id),
            },
            {
              label: 'Manage payments',
              action: (course) =>
                this.$router.push({ path: `/course/${course.id}` }),
            },
          ]
        : [
            {
              label: 'Manage payments',
              action: (course) =>
                this.$router.push({ path: `/course/${course.id}` }),
            },
          ];
    },
  },

  methods: {
    ...mapActions('courses', ['bindCoursesRef']),
    ...mapActions('users', ['bindUsersRef']),
  },
};
</script>
